/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'
import { Caterer } from '@/api/interfaces/caterer'

export interface CatererListState {
  list: Array<Caterer>
  totalItems: number
  isLoading: boolean
}

@Module({ name: 'catererList', namespaced: true })
class CatererListModule extends VuexModule
  implements CatererListState {
  public list: Array<Caterer> = []
  public totalItems = 0
  public isLoading = false

  @Mutation
  public setList (list: Array<Caterer>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setIsLoading (isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<Caterer>> {
    try {
      this.context.commit('setIsLoading', true)
      this.context.commit('setList', [])
      this.context.commit('setTotalItems', 0)
      const option = constructUrlQueryString(queryParams)
      const response = await http.get(`/api/caterers${option}`)
      this.context.commit('setList', response.data['hydra:member'])
      this.context.commit('setTotalItems', response.data['hydra:totalItems'])
      this.context.commit('setIsLoading')
      return response.data['hydra:member'] as Array<Caterer>
    } finally {
      this.context.commit('setIsLoading', false)
    }
  }

  @Action({ rawError: true })
  public async deleteById (id: number): Promise<Array<Caterer>> {
    this.context.commit('setIsLoading', true)
    try {
      const response = await http.delete(`/api/caterers/${id}`)
      this.context.commit('setList', this.list.filter(item => item.id !== id))
      this.context.commit('setTotalItems', this.totalItems - 1)
      return response.data['hydra:member'] as Array<Caterer>
    } finally {
      this.context.commit('setIsLoading', false)
    }
  }
}

export default CatererListModule
