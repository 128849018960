/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import http from '@/plugins/axios'
import { NotificationEmail } from '@/api/interfaces/notificationemail'
import { EmailNotificationTypeDocument } from '@/api/enums/emailNotificationTypeDocument'
import { EmailNotificationType } from '@/api/enums/emailNotificationType'
import { NotificationEmailAttestationIndividual } from '@/enums/notificationEmailAttestationIndividual'
import { NotificationEmailAttestationCompany } from '@/enums/notificationEmailAttestationCompany'
import { NotificationEmailConvocationIndividual } from '@/enums/notificationEmailConvocationIndividual'
import { NotificationEmailConvocationCompany } from '@/enums/notificationEmailConvocationCompany'
import { NotificationEmailConventionIndividual } from '@/enums/notificationEmailConventionIndividual'
import { NotificationEmailConventionCompany } from '@/enums/notificationEmailConventionCompany'

export interface NotificationEmailFormState {
  notificationEmail: NotificationEmail
  isChanged: boolean
  isSubmitted: boolean
}

  export function newNotificationEmail (): NotificationEmail {
  return {
    '@id': '',
    emailNotificationTypeDocument: undefined,
    emailNotificationType: undefined,
    emailNotificationBrand: undefined,
    trainingType: undefined,
    object: '',
    content: '',
    isPermanent: false,
  }
}

@Module({ name: 'notificationEmailForm', namespaced: true })
class NotificationEmailFormModule extends VuexModule implements NotificationEmailFormState {
  public notificationEmail: NotificationEmail = newNotificationEmail();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setNotificationEmail (notificationEmail: NotificationEmail): void {
    this.notificationEmail = notificationEmail
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean):void {
    this.isSubmitted = submitted
  }

  @Mutation setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  get getNotificationEmail (): NotificationEmail {
    return this.notificationEmail
  }

  get getVariables (): Array<string> {
    let variables: Array<string> = []

    if (this.notificationEmail.emailNotificationType === undefined) {
      return variables
    }

    switch (this.notificationEmail.emailNotificationTypeDocument) {
      case EmailNotificationTypeDocument.ATTESTATION:
        this.notificationEmail.emailNotificationType === EmailNotificationType.INDIVIDUAL
          ? variables = Object.values(NotificationEmailAttestationIndividual)
          : variables = Object.values(NotificationEmailAttestationCompany)
        break
      case EmailNotificationTypeDocument.CONVOCATION:
        this.notificationEmail.emailNotificationType === EmailNotificationType.INDIVIDUAL
          ? variables = Object.values(NotificationEmailConvocationIndividual)
          : variables = Object.values(NotificationEmailConvocationCompany)
        break
      case EmailNotificationTypeDocument.CONVENTION:
        this.notificationEmail.emailNotificationType === EmailNotificationType.INDIVIDUAL
          ? variables = Object.values(NotificationEmailConventionIndividual)
          : variables = Object.values(NotificationEmailConventionCompany)
        break
      default:
    }
    return variables
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<NotificationEmail> {
    const response = await http.get(`/api/notification_emails/${id}`)
    const notificationEmail = response.data as NotificationEmail
    this.context.commit('setNotificationEmail', notificationEmail)
    return notificationEmail
  }

  @Action({ rawError: true })
  public async save (): Promise<NotificationEmail> {
    this.context.commit('setIsSubmitted', true)
    try {
      const notificationEmail: NotificationEmail = cloneObjectReplaceSubObjectByIRI(this.notificationEmail)
      if (notificationEmail['@id']) {
        const response = await http.put(notificationEmail['@id'] as string, notificationEmail)
        this.context.commit('setNotificationEmail', response.data)
      } else {
        const response = await http.post('/api/notification_emails', notificationEmail)
        this.context.commit('setNotificationEmail', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
     return this.notificationEmail
  }

  @Action({ rawError: true })
  public async reset (): Promise<NotificationEmail> {
    const notificationEmail: NotificationEmail = newNotificationEmail()
    this.context.commit('setNotificationEmail', notificationEmail)
    this.context.commit('setIsSubmitted', false)
    return notificationEmail
  }
}

export default NotificationEmailFormModule
