<script lang="ts">
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'Loading',

    props: {
      message: {
        type: String,
        required: false,
        default: null,
      },
      classes: {
        type: String,
        required: false,
        default: '',
      },
      size: {
        type: Number,
        required: false,
        default: 50,
      },
    },
  })
</script>

<template>
  <div
    class="progress-circular-wrapper"
    :class="classes ? classes : null"
  >
    <v-progress-circular
      :size="size"
      color="primary"
      indeterminate
    />
    <p
      v-if="message"
      class="font-weight-bold text-h6"
    >
      {{ message }}
    </p>
  </div>
</template>

<style scoped>
.progress-circular-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
}
</style>
