/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import { Caterer } from '@/api/interfaces/caterer'
import { Contact } from '@/api/interfaces/contact'
import { CatererPrice } from '@/api/interfaces/catererPrice'

export interface CatererFormState {
  caterer: Caterer
  isChanged: boolean,
  isSubmitted: boolean,
  isLoading: boolean,
}

function newCaterer (): Caterer {
  return {
    '@id': '',
    name: '',
    address: '',
    zipCode: '',
    city: '',
    phoneNumber: '',
    email: '',
    commentary: '',
    prices: [],
    contacts: [],
  }
}

@Module({ name: 'catererForm', namespaced: true })
class CatererFormModule extends VuexModule implements CatererFormState {
  public caterer: Caterer = newCaterer();
  public isChanged = false;
  public isSubmitted = false;
  public isLoading = false;

  @Mutation
  public setCaterer (caterer: Caterer): void {
    this.caterer = caterer
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation
  public setIsLoading (loading: boolean): void {
    this.isLoading = loading
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  @Mutation
  public addOrUpdateContact (params: { contact: Contact, index: number }): void {
    if (params.index !== -1) {
      (this.caterer.contacts as Array<Contact>).splice(params.index, 1, params.contact)
    } else {
      (this.caterer.contacts as Array<Contact>).push(params.contact)
    }

    this.isChanged = true
  }

  @Mutation
  public removeContact (index: number): void {
    (this.caterer.contacts as Array<Contact>).splice(index, 1)
    this.isChanged = true
  }

  @Mutation
  public removePrice (index: number): void {
    (this.caterer.prices as Array<CatererPrice>).splice(index, 1)
    this.isChanged = true
  }

  @Mutation
  public toggleMainContact (contact: Contact): void {
    this.isChanged = true

    if (contact.main) {
      contact.main = false
      return
    }

    const contacts = this.caterer.contacts as Array<Contact>
    contacts.forEach((c: Contact) => {
      c.main = false
    })
    contact.main = true
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Caterer> {
    try {
      this.context.commit('setIsLoading', true)
      const response = await http.get(`/api/caterers/${id}`)
      const caterer = response.data as Caterer
      this.context.commit('setCaterer', caterer)
      return caterer
    } finally {
      this.context.commit('setIsLoading', false)
    }
  }

  @Action({ rawError: true })
  public async save (): Promise<Caterer> {
    this.context.commit('setIsSubmitted', true)
    try {
      const caterer: Caterer = cloneObjectReplaceSubObjectByIRI(this.caterer)
      caterer.contacts = this.caterer.contacts
      if (this.caterer['@id']) {
        const response = await http.put(this.caterer['@id'], caterer)
        this.context.commit('setCaterer', response.data)
      } else {
        const response = await http.post('/api/caterers', caterer)
        this.context.commit('setCaterer', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.caterer
  }

  @Action({ rawError: true })
  public async reset (): Promise<Caterer> {
    const caterer:Caterer = newCaterer()
    this.context.commit('setCaterer', caterer)
    this.context.commit('setIsSubmitted', false)
    return caterer
  }
}

export default CatererFormModule
