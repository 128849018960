/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import { Hotel } from '@/api/interfaces/hotel'
import { Contact } from '@/api/interfaces/contact'
import { HotelPrice } from '@/api/interfaces/hotelPrice'

export interface HotelFormState {
  hotel: Hotel
  isChanged: boolean,
  isSubmitted: boolean,
  isLoading: boolean,
}

function newHotel (): Hotel {
  return {
    '@id': '',
    name: '',
    address: '',
    zipCode: '',
    city: '',
    phoneNumber: '',
    email: '',
    reservationMethod: undefined,
    commentary: '',
    prices: [],
    contacts: [],
  }
}

@Module({ name: 'hotelForm', namespaced: true })
class HotelFormModule extends VuexModule implements HotelFormState {
  public hotel: Hotel = newHotel();
  public isChanged = false;
  public isSubmitted = false;
  public isLoading = false;

  @Mutation
  public setHotel (hotel: Hotel): void {
    this.hotel = hotel
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation
  public setIsLoading (loading: boolean): void {
    this.isLoading = loading
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  @Mutation
  public addOrUpdateContact (params: { contact: Contact, index: number }): void {
    if (params.index !== -1) {
      (this.hotel.contacts as Array<Contact>).splice(params.index, 1, params.contact)
    } else {
      (this.hotel.contacts as Array<Contact>).push(params.contact)
    }

    this.isChanged = true
  }

  @Mutation
  public removeContact (index: number): void {
    (this.hotel.contacts as Array<Contact>).splice(index, 1)
    this.isChanged = true
  }

  @Mutation
  public removePrice (index: number): void {
    (this.hotel.prices as Array<HotelPrice>).splice(index, 1)
    this.isChanged = true
  }

  @Mutation
  public toggleMainContact (contact: Contact): void {
    this.isChanged = true

    if (contact.main) {
      contact.main = false
      return
    }

    const contacts = this.hotel.contacts as Array<Contact>
    contacts.forEach((c: Contact) => {
      c.main = false
    })
    contact.main = true
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Hotel> {
    try {
      this.context.commit('setIsLoading', true)
      const response = await http.get(`/api/hotels/${id}`)
      const hotel = response.data as Hotel
      this.context.commit('setHotel', hotel)
      return hotel
    } finally {
      this.context.commit('setIsLoading', false)
    }
  }

  @Action({ rawError: true })
  public async save (): Promise<Hotel> {
    this.context.commit('setIsSubmitted', true)
    try {
      const hotel: Hotel = cloneObjectReplaceSubObjectByIRI(this.hotel)
      hotel.contacts = this.hotel.contacts
      if (this.hotel['@id']) {
        const response = await http.put(this.hotel['@id'], hotel)
        this.context.commit('setHotel', response.data)
      } else {
        const response = await http.post('/api/hotels', hotel)
        this.context.commit('setHotel', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.hotel
  }

  @Action({ rawError: true })
  public async reset (): Promise<Hotel> {
    const hotel = newHotel()
    this.context.commit('setHotel', hotel)
    this.context.commit('setIsSubmitted', false)
    return hotel
  }
}

export default HotelFormModule
