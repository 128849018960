/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Company } from '@/api/interfaces/company'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'

export interface CompanyState {
  list: Array<Company>
  totalItems: number
  loading: boolean
  isLoadingTransfer: boolean
  isLoadingDelete: boolean
}

function newCompany (): Company {
  return {
    '@id': '',
    id: 0,
    name: '',
    nameComplementary: '',
    businessIntroducer: false,
    city: '',
    companyContacts: [],
    address: '',
    clientType: '',
    type: '',
    zipCode: '',
    phoneNumber: '',
    secondPhoneNumber: '',
    email: '',
    website: '',
    siret: '',
    traineeImportTypes: [],
    parentCompany: undefined,
    commentary: '',
    accountType: '',
    trainingOfficersDriverProgram: [],
    trainingOfficersOthers: [],
    commercials: [],
    relationClient: undefined,
    fleetSize: undefined,
    bankAccount: '',
    paymentPeriod: undefined,
    nafCode: undefined,
    isActive: true,
  }
}

@Module({ name: 'Company', namespaced: true })
class CompanyModule extends VuexModule implements CompanyState {
  public list: Array<Company> = []
  public totalItems = 0
  public loading = false
  public isLoadingTransfer = false
  public isLoadingDelete = false

  @Mutation
  public setList (list: Array<Company>): void {
    this.list = list
  }

  @Mutation
  public addCompany (company: Company): void {
    this.list.push(company)
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setLoading (loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setIsLoadingTransfer (isLoadingTransfer: boolean): void {
    this.isLoadingTransfer = isLoadingTransfer
  }

  @Mutation
  public setIsLoadingDelete (isLoadingDelete: boolean): void {
    this.isLoadingDelete = isLoadingDelete
  }

  @Action({ rawError: true })
  public async save (name: string): Promise<Company> {
    const companyObject = newCompany()
    companyObject.name = name
    const response = await http.post('/api/companies', companyObject)
    this.context.commit('addCompany', response.data)
    return response.data as Company
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<Company>> {
    try {
      this.context.commit('setLoading', true)
      this.context.commit('setList', [])
      this.context.commit('setTotalItems', 0)
      const option = constructUrlQueryString(queryParams)
      const response = await http.get(`/api/companies${option}`)
      this.context.commit('setList', response.data['hydra:member'])
      this.context.commit('setTotalItems', response.data['hydra:totalItems'])
      return response.data['hydra:member'] as Array<Company>
    } finally {
      this.context.commit('setLoading', false)
    }
  }

  @Action({ rawError: true })
  public async loadByID (id: string): Promise<Company> {
    const response = await http.get(`/api/companies/${id}`)
    return response.data as unknown as Company
  }

  @Action({ rawError: true })
  public async delete (id: number): Promise<void> {
    try {
      this.context.commit('setIsLoadingDelete', true)
      await http.delete(`/api/companies/${id}`)
      this.context.commit('setList', (this.context.state as CompanyState).list.filter((company: Company) => company.id !== id))
    } finally {
      this.context.commit('setIsLoadingDelete', false)
    }
  }

  @Action({ rawError: true })
  public async transfer (params: {
    idCompany: number,
    idTargetCompany: number
  }): Promise<void> {
    try {
      this.context.commit('setIsLoadingTransfer', true)
      await http.post(
        `/api/companies/${params.idCompany}/transfer_to/${params.idTargetCompany}`,
        {},
      )
    } finally {
      this.context.commit('setIsLoadingTransfer', false)
    }
  }
}

export default CompanyModule
