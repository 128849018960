<template>
  <div v-if="$vuetify.theme.dark">
    <span class="logo-mini">
      <img :src="require(`@/assets/logos/white-logo-icon.svg`)">
    </span>
    <span class="logo-normal d-none d-sm-inline">
      <img :src="require(`@/assets/logos/white-logo-text.svg`)">
    </span>
  </div>
  <div v-else>
    <span class="logo-mini">
      <img :src="require(`@/assets/logos/dark-logo-icon.svg`)">
    </span>
    <span class="logo-normal d-none d-sm-inline">
      <img :src="require(`@/assets/logos/dark-logo-text.svg`)">
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'Logo',
  })

</script>
