export function useFile () {
  function convertBase64ToFile (base64: string, type: string, filename: string): File {
    const base64Data = base64.split(',')[1]
    const byteCharacters = atob(base64Data)
    const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0))
    const byteArray = new Uint8Array(byteNumbers)

    const blob = new Blob([byteArray], { type: type })

    return new File([blob], filename, { type: type })
  }

  function convertBinaryToBlob (binary: string, type: string): string {
    const blob = new Blob([binary], { type: type })

    return URL.createObjectURL(blob)
  }

  return {
    convertBase64ToFile,
    convertBinaryToBlob,
  }
}
