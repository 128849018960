<template>
  <v-snackbar
    :class="classes"
    :value="value"
    elevation="0"
    v-bind="{
      ...$attrs,
      ...$props,
      'color': 'transparent'
    }"
    @input="$emit('input', $event)"
  >
    <base-material-alert
      :color="color"
      :dismissible="dismissible"
      :type="type"
      class="ma-0"
      :dark="$vuetify.theme.dark"
    >
      <slot />
    </base-material-alert>
  </v-snackbar>
</template>
<script lang="ts">
  import { defineComponent } from '@vue/composition-api'
  // Components
  import { VSnackbar } from 'vuetify/lib'

  declare module 'vue/types/vue' {
    interface VueConstructor {
      options: {
        computed: {
          classes: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            call: any
          }
        }
      }
    }
  }

  export default defineComponent({
    name: 'BaseMaterialSnackbar',

    extends: VSnackbar,

    props: {
      dismissible: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: '',
      },
    },

    computed: {
      classes () {
        return {
          ...VSnackbar.options.computed.classes.call(this),
          'v-snackbar--material': true,
        }
      },
    },
  })
</script>

<style lang="sass">
  .v-snackbar--material
    margin-top: 32px
    margin-bottom: 32px

    .v-alert--material,
    .v-snack__wrapper
      border-radius: 4px

    .v-snack__content
      overflow: visible
      padding: 0
</style>
