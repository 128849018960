<template>
  <v-menu
    :dark="$vuetify.theme.dark"
    bottom
    origin="center center"
    transition="scale-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mx-4 filter-header"
        v-bind="attrs"
        small
        :color="value && (multiple ? value.length > 0 : true) ? 'primary' : 'grey'"
        v-on="on"
      >
        mdi-filter
      </v-icon>
    </template>
    <v-card
      :dark="$vuetify.theme.dark"
      inline
      class="px-5 my-0"
      outlined
    >
      <v-autocomplete
        v-model="filterValue"
        class="pa-4"
        type="text"
        :items="itemsComputed"
        :item-value="itemValue"
        :item-text="itemText"
        :return-object="returnObject"
        :label="translation"
        :multiple="multiple"
        :chips="multiple"
        :deletable-chips="multiple"
        :menu-props="{ offsetY: true }"
        :loading="loading"
        :search-input.sync="searchText"
        @change="filter"
      />
      <v-btn
        class="ml-2 mb-2"
        :dark="$vuetify.theme.dark"
        small
        text
        :disabled="value === undefined || value === null || value.length === 0"
        color="primary"
        @click="clear()"
      >
        {{ $t('btn.clear') }}
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'

  interface SelectItem {
    '@id': string
    label: string
  }

  export default defineComponent({
    name: 'ListSelectFilter',
    data () {
      return {
        filterValue: undefined as string | unknown[] | undefined,
        searchText: '',
        debounceTimeoutId: 0,
      }
    },
    props: {
      translation: {
        type: String,
      },
      value: {
        type: [String, Array],
      },
      items: {
        type: Array,
      },
      itemValue: {
        type: Function,
        default: (item: SelectItem) => {
          return item['@id']
        },
      },
      itemText: {
        type: Function,
        default: (item: SelectItem) => {
          return item.label
        },
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
    },
    created () {
      if (this.multiple && !Array.isArray(this.value)) {
        this.filterValue = [this.value]
        return
      }
      (this.filterValue as unknown) = this.value
    },
    methods: {
      filter () {
        const value = this.filterValue
        this.$emit('filter-list', value)
      },
      clear () {
        if (this.multiple) {
          this.filterValue = []
          this.$emit('filter-list', [])
          return
        }
        this.filterValue = ''
        this.$emit('filter-list', '')
      },
    },
    computed: {
      itemsComputed (): any {
        if (this.multiple && this.returnObject && this.value && Array.isArray(this.value) && this.value.length > 0) {
          const fusion: any = [
            ...(this.value as SelectItem[]),
            ...(this.items as SelectItem[]),
          ]
          return [
            // remove duplicates from the fusion
            ...new Map(
              fusion.map((item: { id: any }) => [item.id, item]),
            ).values(),
          ]
        }
        return this.items
      },
    },
    watch: {
      async searchText () {
        if (this.selectedItem && !this.multiple) {
          const currentLabel = (this.itemText as CallableFunction)(this.selectedItem)
          if (this.searchText === currentLabel) {
            return
          }
        }
        window.clearTimeout(this.debounceTimeoutId)
        this.debounceTimeoutId = window.setTimeout(async () => {
          await this.$emit('search', this.searchText)
        }, 200)
      },
      value: {
        handler (newValue: unknown) {
          if (this.multiple && !Array.isArray(newValue)) {
            this.filterValue = [newValue]
            return
          }
          (this.filterValue as unknown) = newValue
        },
        immediate: true,
      },
    },
  })
</script>
<style lang="scss" scoped>
.v-menu__content {
  margin-top: 20px;
}
</style>
