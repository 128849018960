<template>
  <v-btn
    v-show="scrollTop"
    v-scroll="onScroll"
    fab
    :dark="$vuetify.theme.dark"
    fixed
    bottom
    right
    color="primary"
    @click="toTop"
  >
    <v-icon>
      mdi-arrow-up
    </v-icon>
  </v-btn>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'ScrollTop',

    data () {
      return {
        scrollTop: false,
      }
    },

    methods: {
      onScroll (e: any) {
        if (typeof window === 'undefined') return
        if (e === null) return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.scrollTop = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      },
    },
  })
</script>
