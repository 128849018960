<template>
  <v-menu
    :dark="$vuetify.theme.dark"
    bottom
    origin="center center"
    transition="scale-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mx-4 filter-header"
        v-bind="attrs"
        small
        :color="value && (multiple ? value.length > 0 : true) ? 'primary' : 'grey'"
        v-on="on"
      >
        mdi-filter
      </v-icon>
    </template>
    <v-card
      :dark="$vuetify.theme.dark"
      inline
      class="px-5 my-0"
      outlined
    >
      <select-training-officer
        v-model="filterValue"
        :items="items"
        :loading="loading"
        :label="translation"
        :multiple="multiple"
        :menu-props="{ offsetY: true }"
        classes="pa-4"
        @change="filter"
      />
      <v-btn
        class="ml-2 mb-2"
        :dark="$vuetify.theme.dark"
        small
        text
        :disabled="value === undefined || value.length === 0"
        color="primary"
        @click="clear()"
      >
        {{ $t('btn.clear') }}
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'
  import SelectTrainingOfficer from '@/components/molecules/select/trainingOfficer.vue'

  export default defineComponent({
    name: 'ListSelectUserFilter',
    components: { SelectTrainingOfficer },
    data () {
      return {
        filterValue: '',
      }
    },
    props: {
      translation: {
        type: String,
      },
      value: {
        type: [String, Array],
      },
      items: {
        type: Array,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    created () {
      (this.filterValue as unknown) = this.value
    },
    methods: {
      filter () {
        const value = this.filterValue
        this.$emit('filter-list', value)
      },
      clear () {
        this.filterValue = ''
        this.$emit('filter-list', '')
      },
    },
    watch: {
      value: {
        handler (newValue) {
          this.filterValue = newValue
        }
      }
    },
  })
</script>
<style lang="scss" scoped>
.v-menu__content {
  margin-top: 20px;
}
</style>
