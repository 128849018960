// Back enum: update enum OriginDriverProgram.php
export enum OriginDriverProgram {
  TOYOTA = 'TOYOTA',
  LEXUS = 'LEXUS',
  DALKIA = 'DALKIA',
  ARVAL = 'ARVAL',
  CALEF = 'CALEF',
  ACTUA = 'ACTUA',
  FORMATION = 'FORMATION',
  OTHERS = 'OTHERS',
}
