// Back enum: update enum Brand.php
export enum Brand {
  TOYOTA = 'TOYOTA',
  LEXUS = 'LEXUS',
  DALKIA = 'DALKIA',
  ARVAL = 'ARVAL',
  CALEF = 'CALEF',
  ACTUA = 'ACTUA',
  FORMATION = 'FORMATION',
}
