// Enum for listing variables usable in email templates for Convention send by individual
export enum NotificationEmailConventionIndividual {
  MANAGER_FIRST_NAME = 'manager_first_name',
  MANAGER_LAST_NAME = 'manager_last_name',
  TRAINING_TYPE_NAME = 'training_type_name',
  TRAINING_LOCATION_ADDRESS = 'training_location_address',
  TRAINING_LOCATION_CITY = 'training_location_city',
  TRAINING_LOCATION_ZIP_CODE = 'training_location_zip_code',
  TRAINING_SESSION_DATE = 'training_session_date',
  COMPANY_NAME = 'company_name',
}
