<template>
  <v-menu
    :dark="$vuetify.theme.dark"
    bottom
    origin="center center"
    transition="scale-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mx-4 filter-header"
        v-bind="attrs"
        small
        :color="value ? 'primary' : 'grey'"
        v-on="on"
      >
        mdi-filter
      </v-icon>
    </template>
    <v-card
      :dark="$vuetify.theme.dark"
      inline
      class="px-5 mb-0"
      outlined
    >
      <v-text-field
        v-model="filterValue"
        class="pa-4"
        type="text"
        :label="translation"
        @input="filter"
      />
      <v-btn
        class="ml-2 mb-2"
        :dark="$vuetify.theme.dark"
        small
        text
        :disabled="value.length === 0"
        color="primary"
        @click="clear()"
      >
        {{ $t('btn.clear') }}
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'ListTextFilter',
    data () {
      return {
        filterValue: '',
      }
    },
    props: {
      translation: {
        type: String,
      },
      value: {
        type: String,
        default: '',
      },
    },
    created () {
      this.filterValue = this.value
    },
    methods: {
      filter () {
        const value = this.filterValue
        this.$emit('filter-list', value)
      },
      clear () {
        this.filterValue = ''
        this.$emit('filter-list', '')
      },
    },
    watch: {
      value: {
        handler (newValue) {
          this.filterValue = newValue
        }
      }
    },
  })
</script>
