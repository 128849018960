/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'
import { VehicleAvailability } from '@/api/interfaces/vehicleAvailability'

export interface VehicleAvailabilityState {
  items: Array<VehicleAvailability>
  isLoading: boolean
}

@Module({ name: 'vehicleAvailability', namespaced: true })
class VehicleAvailabilityModule extends VuexModule implements VehicleAvailabilityState {
  public items: Array<VehicleAvailability> = []
  public isLoading = false

  @Mutation
  public setItems (items: Array<VehicleAvailability>): void {
    this.items = items
  }

  @Mutation
  public setIsLoading (isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Action({ rawError: true })
  public async load (params: {
    queryParams: QueryParams,
    idLogisticSession: number,
  }): Promise<Array<VehicleAvailability>> {
    try {
      this.context.commit('setIsLoading', true)
      const option = constructUrlQueryString(params.queryParams)
      const response = await http.get(`/api/vehicles_availability/logistic_sessions/${params.idLogisticSession}${option}`)
      this.context.commit('setItems', response.data['hydra:member'])
      return response.data['hydra:member'] as Array<VehicleAvailability>
    } finally {
      this.context.commit('setIsLoading', false)
    }
  }
}

export default VehicleAvailabilityModule
