<template>
  <div class="d-flex align-items-center">
    <v-avatar
      v-if="user.initials"
      :size="size"
      color="secondary"
      class="text-caption"
    >
      {{ user.initials }}
    </v-avatar>
    <div
      v-if="showName"
      class="ml-2 my-auto"
    >
      {{ user.firstName }} {{ user.lastName }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'Initials',
    props: {
      user: {
        type: Object,
        required: true,
      },
      showName: {
        type: Boolean,
        default: true,
      },
      size: {
        type: Number,
        default: 30,
      },
    },
  })
</script>
