/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { FiltersParams } from '@/views/dashboard/trainee/store/filterList'
import { NeedTrainingStatus } from '@/api/enums/needTrainingStatus'

export interface changeStatusFormState {
  isSubmited: boolean
  totalItem: number
  statusChanged: number
}

@Module({ name: 'changeStatusForm', namespaced: true })
class ChangeStatusFormModule extends VuexModule implements changeStatusFormState {
  public isSubmited = false;
  public statusChanged = 0;
  public totalItem = 0;

  @Mutation
  public setIsSubmitted (submited: boolean): void {
    this.isSubmited = submited
  }

  @Mutation
  public setTotalItem (totalItem: number): void {
    this.totalItem = totalItem
  }

  @Mutation
  public setStatusChanged (statusChanged: number): void {
    this.statusChanged = statusChanged
  }

  @Action({ rawError: true })
  public async massChangeStatus (params: {status: NeedTrainingStatus, filters: FiltersParams}): Promise<boolean> {
    this.context.commit('setIsSubmitted', true)
    try {
      const response = await http.post('/api/need_trainings/mass_change_status', params)
      this.context.commit('setTotalItem', response.data.totalItem)
      this.context.commit('setStatusChanged', response.data.statusChanged)
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return true
  }

  @Action({ rawError: true })
  public async reset () {
    this.context.commit('setStatus', '')
    this.context.commit('setIsSubmitted', false)
  }
}

export default ChangeStatusFormModule
