/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'
import { TeachingToolAvailability } from '@/api/interfaces/teachingToolAvailability'

export interface TeachingToolAvailabilityState {
  items: Array<TeachingToolAvailability>
  isLoading: boolean
}

@Module({ name: 'teachingToolAvailability', namespaced: true })
class TeachingToolAvailabilityModule extends VuexModule implements TeachingToolAvailabilityState {
  public items: Array<TeachingToolAvailability> = []
  public isLoading = false

  @Mutation
  public setItems (items: Array<TeachingToolAvailability>): void {
    this.items = items
  }

  @Mutation
  public setIsLoading (isLoading: boolean): void {
    this.isLoading = isLoading
  }

  @Action({ rawError: true })
  public async load (params: {
    queryParams: QueryParams,
    idLogisticSession: number,
  }): Promise<Array<TeachingToolAvailability>> {
    try {
      this.context.commit('setIsLoading', true)
      const option = constructUrlQueryString(params.queryParams)
      const response = await http.get(`/api/teaching_tools_availability/logistic_sessions/${params.idLogisticSession}${option}`)
      this.context.commit('setItems', response.data['hydra:member'])
      return response.data['hydra:member'] as Array<TeachingToolAvailability>
    } finally {
      this.context.commit('setIsLoading', false)
    }
  }
}

export default TeachingToolAvailabilityModule
