<script lang="ts">
  import { defineComponent } from '@vue/composition-api'
  import { VCard } from 'vuetify/lib'

  export default defineComponent({
    name: 'Card',

    extends: VCard,
  })
</script>
