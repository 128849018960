<template>
  <list-select-filter
    v-model="trainingLocations"
    :multiple="multiple"
    :translation="$t('training_location.list.filter.name')"
    :items="trainingLocationList"
    :item-text="itemText"
    :item-value="itemValue"
    :return-object="multiple"
    :loading="loading"
    @filter-list="emitFilterList($event)"
    @search="loadTrainingLocations($event)"
  />
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'
  import ListSelectFilter from '@/components/base/ListSelectFilter.vue'
  import { TrainingLocation } from '@/api/interfaces/traininglocation'
  import { QueryParams } from '@/store/api-plateform-utils'
  import { mapActions } from 'vuex'

  interface SelectItem {
    id: string
    label: string
  }

  export default defineComponent({
    name: 'ListSelectTrainingLocationFilter',
    components: { ListSelectFilter },
    props: {
      value: {
        type: [Array as () => Array<string>, String],
      },
      items: {
        type: Array,
      },
      itemValue: {
        type: Function,
        default: (item: SelectItem) => {
          return item['@id']
        },
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        loading: false,
        trainingLocations: null as Array<TrainingLocation> | TrainingLocation | null,
        trainingLocationList: [] as Array<TrainingLocation>,
      }
    },
    methods: {
      ...mapActions('trainingLocationList', {
        load: 'loadWithoutCommitInStore',
      }),
      ...mapActions('trainingLocationForm', {
        loadById: 'loadById',
        loadByIri: 'loadByIri',
      }),
      itemText (item: TrainingLocation) {
        let itemText = ''
        if (item?.name) {
          itemText += item.name
        }
        if (item?.zipCode) {
          itemText += ' ' + item.zipCode
        }
        if (item?.city) {
          itemText += ' ' + item.city
        }

        return itemText
      },
      async loadTrainingLocations (searchText: string) {
        const request: QueryParams = {
          page: 1,
          itemsPerPage: 30,
        }

        if (searchText) {
          request.location = searchText
        }

        this.loading = true
        this.trainingLocationList = await this.load(request)
        this.loading = false
      },
      emitFilterList (items: Array<TrainingLocation> | TrainingLocation) {
        this.trainingLocations = items

        if (Array.isArray(items)) {
          this.$emit('filter-list', items.map((item: TrainingLocation) => this.itemValue(item)))
        } else {
          this.$emit('filter-list', this.itemValue(this.trainingLocations as TrainingLocation))
        }
      },
      async getTrainingLocationObject (item: string|number): Promise<TrainingLocation|null> {
        if (typeof item === 'string' && item.startsWith('/api/training_locations')) {
          return await this.loadByIri(item)
        } else if (!isNaN(Number(item))) {
          return await this.loadById(item)
        }
        return null
      }
    },
    async created () {
      if (Array.isArray(this.value)) {
        this.trainingLocations = (await Promise.all(
          this.value.map((item: string) => this.getTrainingLocationObject(item))
        )).filter(Boolean) as TrainingLocation[]
      } else {
        this.trainingLocations = await this.getTrainingLocationObject(this.value as string|number)
      }
    }
  })
</script>

<style lang="scss" scoped>
.v-menu__content {
  margin-top: 20px;
}
</style>
