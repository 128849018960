<template>
  <v-row
    class="mt-3"
    :justify="nbPages > 1 ? 'center' : 'end'"
    align="center"
  >
    <v-col class="pa-6  flex-grow-0  flex-shrink-1">
      <span class="my-auto text-nowrap">{{ $t('list.itemsPerPage') }} : </span>

      <v-select
        v-model="paginationOptions.itemsPerPage"
        light
        solo
        dense
        style="max-width: 80px;margin: auto;"
        class="flex-grow-0 "
        data-test="select-items-per-page"
        :items="itemsPerPageOptions"
      />
    </v-col>
    <v-col
      v-if="nbPages > 1"
      class="flex-grow-1"
    >
      <v-pagination
        v-if="nbPages > 1"
        v-model="paginationOptions.page"
        :length="nbPages"
        :total-visible="10"
        light
      />
    </v-col>
    <v-col class="pa-6 flex-grow-0 flex-shrink-1">
      <span
        v-if="nbPages > 10"
        class="my-auto text-nowrap"
      >{{ $t('list.access_pagination') }} :
      </span>
      <v-text-field
        v-if="nbPages > 10"
        v-model.number="changePaginationPage"
        type="number"
        light
        :max="nbPages"
        :counter="nbPages"
        solo
        dense
        style="max-width: 80px;margin: auto;"
        class="flex-grow-0"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'Pagination',
    data () {
      return {
        changePaginationPage: 1,
      }
    },
    props: {
      paginationOptions: {
        type: Object,
        required: true,
      },
      nbPages: {
        type: Number,
        required: true,
      },
      itemsPerPageOptions: {
        type: Array,
        required: false,
        default: () => [15, 30, 50, 100],
      },
    },
    computed: {
      computedPaginationOptions (): any {
        return Object.assign({}, this.paginationOptions)
      },
    },
    watch: {
      changePaginationPage: {
        handler () {
          if (
            this.changePaginationPage &&
            this.changePaginationPage >= 1 &&
            this.changePaginationPage <= this.nbPages
          ) {
            this.paginationOptions.page = this.changePaginationPage
          }
        },
      },
      computedPaginationOptions: {
        deep: true,
        handler: function (newPaginationOptions, oldPaginationOptions) {
          if (
            newPaginationOptions.itemsPerPage !==
            oldPaginationOptions.itemsPerPage
          ) {
            this.paginationOptions.page = 1
          }
          this.$emit('changePaginationOptions', this.paginationOptions)
        },
      },
    },
  })
</script>
