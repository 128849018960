import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'
import { User } from '@/api/interfaces/user'
import { LogisticSessionTrainingOfficer } from '@/api/interfaces/logisticSessionTrainingOfficer'
import { TrainingOfficerView } from '@/api/enums/trainingOfficerView'

export interface trainingOfficerState {
  listTrainingOfficersDriverProgram: Array<User>
  listTrainingOfficersOthers: Array<User>
  listOfficersSupplierRelationship: Array<User>
  listCommercial: Array<User>
  logisticSessionsOfTrainingOfficer: Array<LogisticSessionTrainingOfficer>
  totalItemsTrainingOfficersDriverProgram: number
  totalItemsTrainingOfficersOthers: number
  totalItemsOfficersSupplierRelationship: number
  totalItemsCommercial: number
  totalItemsLogisticSessionsOfTrainingOfficer: number
  loadingTrainingOfficersDriverProgram: boolean
  loadingTrainingOfficersOthers: boolean
  loadingOfficersSupplierRelationship: boolean
  loadingCommercial: boolean
  loadingLogisticSessionsOfTrainingOfficer: boolean
  submittingTransferSessionsToAnotherTrainingOfficer: boolean
}

const codeUserGroupTrainingOfficerDriverProgram = 'charge-de-formation-driver-program'
const codeUserGroupTrainingOfficerOther = 'charge-de-formation-autres'
const codeUserGroupTrainingSupplierRelationship = 'charge-de-relation-fournisseurs'
const codeUserGroupCommercial = 'commerce'

@Module({ name: 'trainingOfficer', namespaced: true })
class trainingOfficerModule extends VuexModule implements trainingOfficerState {
  public listTrainingOfficersDriverProgram: Array<User> = []
  public listTrainingOfficersOthers: Array<User> = []
  public listOfficersSupplierRelationship: Array<User> = []
  public listCommercial: Array<User> = []
  public logisticSessionsOfTrainingOfficer: Array<LogisticSessionTrainingOfficer> = []

  public totalItemsTrainingOfficersDriverProgram = 0
  public totalItemsTrainingOfficersOthers = 0
  public totalItemsOfficersSupplierRelationship = 0
  public totalItemsCommercial = 0
  public totalItemsLogisticSessionsOfTrainingOfficer = 0

  public loadingTrainingOfficersDriverProgram = false
  public loadingTrainingOfficersOthers = false
  public loadingOfficersSupplierRelationship = false
  public loadingCommercial = false
  public loadingLogisticSessionsOfTrainingOfficer = false
  public submittingTransferSessionsToAnotherTrainingOfficer = false

  @Mutation
  public setListTrainingOfficersDriverProgram (list: Array<User>): void {
    this.listTrainingOfficersDriverProgram = list
  }

  @Mutation
  public setListTrainingOfficersOthers (list: Array<User>): void {
    this.listTrainingOfficersOthers = list
  }

  @Mutation
  public setListOfficersSupplierRelationship (list: Array<User>): void {
    this.listOfficersSupplierRelationship = list
  }

  @Mutation
  public setListCommercial (list: Array<User>): void {
    this.listCommercial = list
  }

  @Mutation
  public setLogisticSessionsOfTrainingOfficer (trainingSessions: Array<LogisticSessionTrainingOfficer>): void {
    this.logisticSessionsOfTrainingOfficer = trainingSessions
  }

  @Mutation
  public setTotalItemsTrainingOfficersDriverProgram (totalItems: number): void {
    this.totalItemsTrainingOfficersDriverProgram = totalItems
  }

  @Mutation
  public setTotalItemsTrainingOfficersOthers (totalItems: number): void {
    this.totalItemsTrainingOfficersOthers = totalItems
  }

  @Mutation
  public setTotalItemsOfficersSupplierRelationship (totalItems: number): void {
    this.totalItemsOfficersSupplierRelationship = totalItems
  }

  @Mutation
  public setTotalItemsCommercial (totalItems: number): void {
    this.totalItemsCommercial = totalItems
  }

  @Mutation
  public setTotalItemsLogisticSessionsOfTrainingOfficer (totalItems: number): void {
    this.totalItemsLogisticSessionsOfTrainingOfficer = totalItems
  }

  @Mutation
  public setLoadingTrainingOfficersDriverProgram (): void {
    this.loadingTrainingOfficersDriverProgram = !this.loadingTrainingOfficersDriverProgram
  }

  @Mutation
  public setLoadingTrainingOfficersOthers (): void {
    this.loadingTrainingOfficersOthers = !this.loadingTrainingOfficersOthers
  }

  @Mutation
  public setLoadingOfficersSupplierRelationship (): void {
    this.loadingOfficersSupplierRelationship = !this.loadingOfficersSupplierRelationship
  }

  @Mutation
  public setLoadingCommercial (): void {
    this.loadingCommercial = !this.loadingCommercial
  }

  @Mutation
  public setLoadingLogisticSessionsOfTrainingOfficer (): void {
    this.loadingLogisticSessionsOfTrainingOfficer = !this.loadingLogisticSessionsOfTrainingOfficer
  }

  @Mutation
  public setSubmittingTransferSessionsToAnotherTrainingOfficer (): void {
    this.submittingTransferSessionsToAnotherTrainingOfficer = !this.submittingTransferSessionsToAnotherTrainingOfficer
  }

  get commercials (): Array<User> {
    return this.listCommercial
  }

  @Action({ rawError: true })
  public async loadTrainingOfficersDriverProgram (queryParams: QueryParams): Promise<Array<User>> {
    queryParams.code = codeUserGroupTrainingOfficerDriverProgram
    this.context.commit('setLoadingTrainingOfficersDriverProgram')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`api/user_groups${option}`)
    this.context.commit('setListTrainingOfficersDriverProgram', response.data['hydra:member'][0]?.users ?? [])
    this.context.commit('setTotalItemsTrainingOfficersDriverProgram', response.data['hydra:member'][0]?.users?.length ?? 0)
    this.context.commit('setLoadingTrainingOfficersDriverProgram')
    return response.data['hydra:member']
  }

  @Action({ rawError: true })
  public async loadTrainingOfficersOthers (queryParams: QueryParams): Promise<Array<User>> {
    queryParams.code = codeUserGroupTrainingOfficerOther
    this.context.commit('setLoadingTrainingOfficersOthers')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`api/user_groups${option}`)
    this.context.commit('setListTrainingOfficersOthers', response.data['hydra:member'][0]?.users ?? [])
    this.context.commit('setTotalItemsTrainingOfficersOthers', response.data['hydra:member'][0]?.users?.length ?? 0)
    this.context.commit('setLoadingTrainingOfficersOthers')
    return response.data['hydra:member']
  }

  @Action({ rawError: true })
  public async loadOfficersSupplierRelationship (queryParams: QueryParams): Promise<Array<User>> {
    queryParams.code = codeUserGroupTrainingSupplierRelationship
    this.context.commit('setLoadingOfficersSupplierRelationship')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`api/user_groups${option}`)
    this.context.commit('setListOfficersSupplierRelationship', response.data['hydra:member'][0]?.users ?? [])
    this.context.commit('setTotalItemsOfficersSupplierRelationship', response.data['hydra:member'][0]?.users?.length ?? 0)
    this.context.commit('setLoadingOfficersSupplierRelationship')
    return response.data['hydra:member']
  }

  @Action({ rawError: true })
  public async loadCommercials (queryParams: QueryParams): Promise<Array<User>> {
    queryParams.code = codeUserGroupCommercial
    this.context.commit('setLoadingCommercial')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`api/user_groups${option}`)
    this.context.commit('setListCommercial', response.data['hydra:member'][0]?.users ?? [])
    this.context.commit('setTotalItemsCommercial', response.data['hydra:member'][0]?.users?.length ?? 0)
    this.context.commit('setLoadingCommercial')
    return response.data['hydra:member']
  }

  @Action({ rawError: true })
  public async loadLogisticSessionsOfTrainingOfficer (params: {
    view: TrainingOfficerView,
    queryParams: QueryParams,
    changeStateIsLoading: boolean,
  }): Promise<Array<LogisticSessionTrainingOfficer>> {
    if (params.changeStateIsLoading) {
      this.context.commit('setLoadingLogisticSessionsOfTrainingOfficer')
    }
    try {
      const option = constructUrlQueryString(params.queryParams)
      const response = await http.get(`api/logistic_sessions/training_officers/view/${params.view}${option}`)
      this.context.commit('setLogisticSessionsOfTrainingOfficer', response.data['hydra:member'])
      this.context.commit('setTotalItemsLogisticSessionsOfTrainingOfficer', response.data['hydra:totalItems'])
      return response.data['hydra:member'] as Array<LogisticSessionTrainingOfficer>
    } finally {
      if (params.changeStateIsLoading) {
        this.context.commit('setLoadingLogisticSessionsOfTrainingOfficer')
      }
    }
  }

  @Action({ rawError: true })
  public async transferLogisticSessionsToAnotherTrainingOfficer (params: {
    trainingOfficerId: number,
    targetTrainingOfficerId: number,
  }): Promise<void> {
    try {
      this.context.commit('setSubmittingTransferSessionsToAnotherTrainingOfficer')
      await http.post(
        `api/logistic_sessions/training_officers/${params.trainingOfficerId}/transfer_sessions_to/${params.targetTrainingOfficerId}`,
        {},
      )
    } finally {
      this.context.commit('setSubmittingTransferSessionsToAnotherTrainingOfficer')
    }
  }

  @Action({ rawError: true })
  public resetLogisticSessionsOfTrainingOfficer (): void {
    this.context.commit('setLogisticSessionsOfTrainingOfficer', [])
    this.context.commit('setTotalItemsLogisticSessionsOfTrainingOfficer', 0)
  }

  @Action({ rawError: true })
  public async updateLogisticSession (params: {
    id: string,
    body: never,
  }): Promise<void> {
    await http.put(`api/logistic_sessions/${params.id}`, params.body)
  }

  @Action({ rawError: true })
  public async updateTrackingDate (params: {
    id: string,
    body: never,
  }): Promise<void> {
    await http.put(`api/tracking_date_logistic_sessions/${params.id}`, params.body)
  }

  @Action({ rawError: true })
  public async updateTrainingOfficerLogisticSessionDetail (params: {
    id: string,
    body: never,
  }): Promise<void> {
    await http.put(`api/training_officer_logistic_session_details/${params.id}`, params.body)
  }

  @Action({ rawError: true })
  public async updateCommentaryTrainingOfficer (params: {
    id: string,
    body: never,
  }): Promise<void> {
    await http.put(`api/commentary_training_officers/${params.id}`, params.body)
  }
}

export default trainingOfficerModule
