<template>
  <router-view />
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'
  export default defineComponent({
    name: 'App',
  })
</script>
