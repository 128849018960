/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Vehicle } from '@/api/interfaces/vehicle'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'

export interface VehicleListState {
  list: Array<Vehicle>
  totalItems: number
  loading: boolean
}

@Module({ name: 'vehicleList', namespaced: true })
class VehicleListModule extends VuexModule implements VehicleListState {
  public list: Array<Vehicle> = []
  public totalItems = 0
  public loading = false

  @Mutation
  public setList (list: Array<Vehicle>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<Vehicle>> {
    this.context.commit('setLoading')
    this.context.commit('setList', [])
    this.context.commit('setTotalItems', 0)
    let option = constructUrlQueryString(queryParams)
    option = option ? option + '&exists[deletedAt]=false' : '?exists[deletedAt]=false'
    const response = await http.get(`/api/vehicles${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<Vehicle>
  }

  @Action({ rawError: true })
  public async deleteById (id: number): Promise<Array<Vehicle>> {
    this.context.commit('setLoading')
    try {
      const response = await http.delete(`/api/vehicles/${id}`)
      this.context.commit('setList', this.list.filter(item => item.id !== id))
      this.context.commit('setTotalItems', this.totalItems - 1)
      return response.data['hydra:member'] as Array<Vehicle>
    } finally {
      this.context.commit('setLoading')
    }
  }
}

export default VehicleListModule
