import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import store from '@/store'
import axios from '@/plugins/axios'
import { User } from './api/interfaces/user'
import { userConnectedHasRoles } from '@/composables/UserGrant'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    component: () => import('@/views/pages/Index.vue'),
    children: [
      {
        name: 'Login',
        path: '',
        meta: { public: true },
        component: () => import('@/views/pages/Login.vue'),
      },
    ],
  },
  {
    path: '/',
    redirect: { name: 'Planning' },
    component: () => import('@/views/dashboard/Index.vue'),
    children: [
      {
        name: 'TrainersSpace',
        path: 'trainers-space',
        redirect: { name: 'Trainers Space Upcoming' },
        component: () => import('@/views/dashboard/trainers-space/Index.vue'),
        children: [
          {
            name: 'Trainers Space Upcoming',
            path: 'upcoming',
            component: () => import('@/views/dashboard/trainers-space/upcoming.vue'),
            meta: {
              roles: ['ROLE_TRAINER'],
            },
          },
          {
            name: 'Trainers Space Historique',
            path: 'historique',
            component: () => import('@/views/dashboard/trainers-space/historique.vue'),
            meta: {
              roles: ['ROLE_TRAINER'],
            },
          },
        ],
      },
      {
        name: 'EmargementIndividual',
        path: '/emargement/:uuid',
        component: () => import('@/views/dashboard/emargement/EmargementIndividual.vue'),
        meta: { public: true },
      },
      {
        name: 'EmargementSession',
          path: '/emargement/session/:uuidSession',
        component: () => import('@/views/dashboard/emargement/EmargementSession.vue'),
        meta: { public: true },
      },
      {
        name: 'Planning',
        path: 'planning',
        component: () => import('@/views/dashboard/planning/index.vue'),
        meta: {
          roles: ['ROLE_READ_PLANNING', 'ROLE_TRAINER_REGION_MANAGER'],
        },
      },
      {
        name: 'Trainee List',
        path: 'trainee',
        component: () => import('@/views/dashboard/trainee/List.vue'),
        meta: { roles: ['ROLE_READ_TRAINEE'] },
      },
      {
        name: 'Trainee Import',
        path: 'trainee-import',
        component: () => import('@/views/dashboard/import/Index.vue'),
        meta: { roles: ['ROLE_READ_TRAINEE_IMPORT'] },
      },
      {
        name: 'Trainee Import Status',
        path: 'trainee-import/status',
        component: () => import('@/views/dashboard/import/NeedTrainingUpdate.vue'),
        meta: { roles: ['ROLE_READ_TRAINEE_IMPORT'] },
      },
      {
        name: 'Trainee Import Call Center',
        path: 'trainee-import/call-center',
        component: () => import('@/views/dashboard/import/NeedTrainingCallCenter.vue'),
        meta: { roles: ['ROLE_READ_TRAINEE_IMPORT'] },
      },
      {
        path: 'trainee',
        redirect: { name: 'Trainee List' },
        component: () => import('@/views/dashboard/trainee/Index.vue'),
        children: [
          {
            name: 'Trainee Create',
            path: 'new/:trainingType?',
            component: () => import('@/views/dashboard/trainee/Trainee.vue'),
            meta: { roles: ['ROLE_WRITE_TRAINEE'] },
          },
          {
            name: 'Trainee Edit',
            path: ':idNeedTraining/identity',
            component: () => import('@/views/dashboard/trainee/Trainee.vue'),
            meta: { roles: ['ROLE_WRITE_TRAINEE', 'ROLE_READ_TRAINEE'] },
          },
          {
            name: 'NeedTraining Edit',
            path: ':idNeedTraining/training',
            component: () =>
              import('@/views/dashboard/trainee/NeedTraining.vue'),
            meta: { roles: ['ROLE_WRITE_NEED_TRAINING', 'ROLE_READ_NEED_TRAINING'] },
          },
        ],
      },
      {
        name: 'TrainingSession List Associate NeedTraining',
        path: 'trainee/:idNeedTraining/associate_training_session',
        component: () =>
          import(
            '@/views/dashboard/trainee/ListAssociateOneTrainingSession.vue'
          ),
        meta: { roles: ['ROLE_WRITE_TRAINING_SESSION'] },
      },
      {
        path: 'equipment',
        redirect: { name: 'Vehicle List' },
        component: () => import('@/views/dashboard/equipment/Index.vue'),
        children: [
          {
            name: 'Vehicle List',
            path: 'vehicle/list',
            component: () =>
              import('@/views/dashboard/equipment/VehicleList.vue'),
            meta: { roles: ['ROLE_READ_VEHICULE'] },
          },
          {
            name: 'TeachingTool List',
            path: 'teaching_tool/list',
            component: () =>
              import('@/views/dashboard/equipment/TeachingToolList.vue'),
            meta: { roles: ['ROLE_READ_TEACHING_TOOL'] },
          },
        ],
      },
      {
        name: 'TeachingTool',
        path: 'teaching_tool',
        redirect: { name: 'TeachingTool List' },
        component: () => import('@/views/dashboard/equipment/TeachingTool.vue'),
        children: [
          {
            name: 'TeachingTool Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_TEACHING_TOOL'] },
          },
          {
            name: 'TeachingTool Edit',
            path: ':idTeachingTool/edit',
            meta: { roles: ['ROLE_WRITE_TEACHING_TOOL', 'ROLE_READ_TEACHING_TOOL'] },
          },
        ],
      },
      {
        name: 'Vehicle',
        path: 'vehicle',
        redirect: { name: 'Vehicle List' },
        component: () => import('@/views/dashboard/equipment/Vehicle.vue'),
        children: [
          {
            name: 'Vehicle Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_VEHICULE'] },
          },
          {
            name: 'Vehicle Edit',
            path: ':idVehicle/edit',
            meta: { roles: ['ROLE_WRITE_VEHICULE', 'ROLE_READ_VEHICULE'] },
          },
        ],
      },
      {
        name: 'Company List',
        path: 'company',
        alias: 'company/list',
        component: () => import('@/views/dashboard/company/List.vue'),
        meta: { roles: ['ROLE_READ_COMPANY'] },
      },
      {
        path: 'company',
        redirect: { name: 'Company List' },
        component: () => import('@/views/dashboard/company/Create-Edit.vue'),
        children: [
          {
            name: 'Company Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_COMPANY'] },
          },
          {
            name: 'Company Edit',
            path: ':idCompany/edit',
            meta: { roles: ['ROLE_WRITE_COMPANY', 'ROLE_READ_COMPANY'] },
          },
        ],
      },
      {
        name: 'TrainingSession List',
        path: 'training_session',
        alias: 'training_session/list',
        component: () => import('@/views/dashboard/trainingSession/List.vue'),
        meta: { roles: ['ROLE_READ_TRAINING_SESSION'] },
      },
      {
        name: 'LogisticSession',
        path: 'logistic_session',
        redirect: { name: 'TrainingSession List' },
        component: () =>
          import('@/views/dashboard/trainingSession/LogisticSession.vue'),
        children: [
          {
            name: 'LogisticSession Edit',
            path: ':idLogisticSession/edit',
            meta: { roles: ['ROLE_READ_LOGISTIC_SESSION', 'ROLE_WRITE_LOGISTIC_SESSION', 'ROLE_TRAINER_REGION_MANAGER'] },
          },
        ],
      },
      {
        name: 'Trainee List Associate TrainingSession',
        path: 'logistic_session/:idLogisticSession/:idTrainingSession/associate_trainee',
        component: () =>
          import(
            '@/views/dashboard/trainingSession/ListTraineesWithNoTrainingSession.vue'
          ),
        meta: {
          roles: ['ROLE_WRITE_TRAINEE'],
        },
      },
      {
        name: 'Equipment List Associate LogisticSession',
        path: 'logistic_session/:idLogisticSession/associate_equipment/:equipmentView',
        component: () => import('@/views/dashboard/trainingSession/ListAssociateEquipment.vue'),
      },
      {
        name: 'TrainingLocation List',
        path: 'training_location',
        alias: 'training_location/list',
        component: () => import('@/views/dashboard/trainingLocation/List.vue'),
        meta: { roles: ['ROLE_READ_TRAINING_LOCATION'] },
      },
      {
        name: 'TrainingLocation',
        path: 'training_location',
        redirect: { name: 'TrainingLocation List' },
        component: () =>
          import('@/views/dashboard/trainingLocation/TrainingLocation.vue'),
        children: [
          {
            name: 'TrainingLocation Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_TRAINING_LOCATION'] },
          },
          {
            name: 'TrainingLocation Edit',
            path: ':idTrainingLocation/edit',
            meta: { roles: ['ROLE_WRITE_TRAINING_LOCATION', 'ROLE_READ_TRAINING_LOCATION'] },
          },
          {
            name: 'TrainingLocation Duplicate',
            path: ':idTrainingLocation/duplicate',
            meta: {
              roles: ['ROLE_WRITE_TRAINING_LOCATION'],
              duplicate: true,
            },
          },
        ],
      },
      {
        name: 'Trainer List',
        path: 'trainer',
        alias: 'trainer/list',
        component: () => import('@/views/dashboard/trainer/List.vue'),
        meta: { roles: ['ROLE_READ_TRAINER'] },
      },
      {
        name: 'Training Officers',
        path: 'training_officers',
        alias: 'training_officers',
        component: () => import('@/views/dashboard/trainingOfficers/List.vue'),
        meta: { roles: ['ROLE_READ_TRAINING_OFFICER'] },
      },
      {
        name: 'Trainer',
        path: 'trainer',
        redirect: { name: 'Trainer List' },
        component: () => import('@/views/dashboard/trainer/Trainer.vue'),
        children: [
          {
            name: 'Trainer Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_TRAINER'] },
          },
          {
            name: 'Trainer Edit',
            path: ':idTrainer/edit',
            meta: { roles: ['ROLE_WRITE_TRAINER', 'ROLE_READ_TRAINER'] },
          },
        ],
      },
      {
        name: 'CarDealer List',
        path: 'car_dealer',
        alias: 'car_dealer/list',
        component: () => import('@/views/dashboard/carDealer/List.vue'),
        meta: { roles: ['ROLE_READ_CAR_DEALER'] },
      },
      {
        name: 'CarDealer',
        path: 'car_dealer',
        redirect: { name: 'CarDealer List' },
        component: () => import('@/views/dashboard/carDealer/CarDealer.vue'),
        children: [
          {
            name: 'CarDealer Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_CAR_DEALER'] },
          },
          {
            name: 'CarDealer Edit',
            path: ':idCarDealer/edit',
            meta: { roles: ['ROLE_WRITE_CAR_DEALER', 'ROLE_READ_CAR_DEALER'] },
          },
        ],
      },
      {
        name: 'CarModel List',
        path: 'car_model',
        alias: 'car_model/list',
        component: () => import('@/views/dashboard/carModel/List.vue'),
        meta: { roles: ['ROLE_READ_CAR_MODEL'] },
      },
      {
        name: 'CarModel',
        path: 'car_model',
        redirect: { name: 'CarModel List' },
        component: () => import('@/views/dashboard/carModel/CarModel.vue'),
        children: [
          {
            name: 'CarModel Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_CAR_MODEL'] },
          },
          {
            name: 'CarModel Edit',
            path: ':idCarModel/edit',
            meta: { roles: ['ROLE_WRITE_CAR_MODEL', 'ROLE_READ_CAR_MODEL'] },
          },
        ],
      },
      {
        path: 'caterer_hotel',
        redirect: { name: 'Caterer List' },
        component: () => import('@/views/dashboard/catererHotel/Index.vue'),
        children: [
          {
            name: 'Caterer List',
            path: 'caterer/list',
            component: () =>
              import('@/views/dashboard/catererHotel/CatererList.vue'),
            meta: { roles: ['ROLE_READ_CATERER'] },
          },
          {
            name: 'Hotel List',
            path: 'hotel/list',
            component: () =>
              import('@/views/dashboard/catererHotel/HotelList.vue'),
            meta: { roles: ['ROLE_READ_HOTEL'] },
          },
        ],
      },
      {
        name: 'Caterer',
        path: 'caterer',
        redirect: { name: 'Caterer List' },
        component: () => import('@/views/dashboard/catererHotel/Caterer.vue'),
        children: [
          {
            name: 'Caterer Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_CATERER'] },
          },
          {
            name: 'Caterer Edit',
            path: ':idCaterer/edit',
            meta: { roles: ['ROLE_WRITE_CATERER', 'ROLE_READ_CATERER'] },
          },
        ],
      },
      {
        name: 'Hotel',
        path: 'hotel',
        redirect: { name: 'Hotel List' },
        component: () => import('@/views/dashboard/catererHotel/Hotel.vue'),
        children: [
          {
            name: 'Hotel Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_HOTEL'] },
          },
          {
            name: 'Hotel Edit',
            path: ':idHotel/edit',
            meta: { roles: ['ROLE_WRITE_HOTEL', 'ROLE_READ_HOTEL'] },
          },
        ],
      },
      {
        name: 'UserGroup List',
        path: 'user_group',
        alias: 'user_group/list',
        component: () => import('@/views/dashboard/userGroup/List.vue'),
        meta: { roles: ['ROLE_READ_USER_GROUP'] },
      },
      {
        name: 'UserGroup',
        path: 'user_group',
        redirect: { name: 'UserGroup List' },
        component: () => import('@/views/dashboard/userGroup/userGroupForm.vue'),
        children: [
          {
            name: 'UserGroup Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_USER_GROUP'] },
          },
          {
            name: 'UserGroup Edit',
            path: ':idUserGroup/edit',
            meta: { roles: ['ROLE_WRITE_USER_GROUP', 'ROLE_READ_USER_GROUP'] },
          },
        ],
      },
      {
        name: 'UserGroup List Associate Grant',
        path: 'user_group/:idUserGroup/associate_grant',
        component: () =>
          import(
            '@/views/dashboard/userGroup/ListAssociateGrant.vue'
            ),
        meta: { roles: ['ROLE_WRITE_USER_GROUP'] },
      },
      {
        name: 'UserGroup List Associate User',
        path: 'user_group/:idUserGroup/associate_user',
        component: () =>
          import(
            '@/views/dashboard/userGroup/ListAssociateUser.vue'
            ),
        meta: { roles: ['ROLE_WRITE_USER_GROUP'] },
      },
      {
        name: 'User List',
        path: 'user',
        alias: 'user/list',
        component: () => import('@/views/dashboard/user/List.vue'),
        meta: { roles: ['ROLE_READ_USER'] },
      },
      {
        name: 'User',
        path: 'user',
        redirect: { name: 'User List' },
        component: () => import('@/views/dashboard/user/userForm.vue'),
        children: [
          {
            name: 'User Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_USER'] },
          },
          {
            name: 'User Edit',
            path: ':idUser/edit',
            meta: { roles: ['ROLE_WRITE_USER', 'ROLE_READ_USER'] },
          },
        ],
      },
      {
        path: 'booking',
        redirect: { name: 'Booking List' },
        component: () => import('@/views/dashboard/booking/Index.vue'),
        meta: { roles: ['ROLE_READ_BOOKING'] },
        children: [
          {
            name: 'Booking List',
            path: 'list',
            component: () => import('@/views/dashboard/booking/List.vue'),
            meta: { roles: ['ROLE_READ_BOOKING'] },
          },
          {
            name: 'Canceled Booking List',
            path: 'canceled/list',
            component: () => import('@/views/dashboard/booking/CanceledList.vue'),
            meta: { roles: ['ROLE_READ_BOOKING'] },
          },
        ],
      },
      {
        name: 'Booking',
        path: 'booking/:idBooking',
        component: () => import('@/views/dashboard/booking/Booking.vue'),
        meta: { roles: ['ROLE_READ_BOOKING'] },
      },
      {
        name: 'Profession List',
        path: 'profession',
        alias: 'profession/list',
        component: () => import('@/views/dashboard/profession/List.vue'),
        meta: { roles: ['ROLE_READ_PROFESSION'] },
      },
      {
        name: 'Profession',
        path: 'profession',
        redirect: { name: 'Profession List' },
        component: () => import('@/views/dashboard/profession/Profession.vue'),
        children: [
          {
            name: 'Profession Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_PROFESSION'] },
          },
          {
            name: 'Profession Edit',
            path: ':idProfession/edit',
            meta: { roles: ['ROLE_WRITE_PROFESSION', 'ROLE_READ_PROFESSION'] },
          },
        ],
      },
      {
        name: 'TrainingType List',
        path: 'training_type',
        alias: 'trainingType/list',
        component: () => import('@/views/dashboard/trainingType/List.vue'),
        meta: { roles: ['ROLE_READ_TRAINING_TYPE'] },
      },
      {
        name: 'TrainingType',
        path: 'training_type',
        redirect: { name: 'TrainingType List' },
        component: () =>
          import('@/views/dashboard/trainingType/TrainingType.vue'),
        children: [
          {
            name: 'TrainingType Create',
            path: 'new',
            meta: { roles: ['ROLE_WRITE_TRAINING_TYPE'] },
          },
          {
            name: 'TrainingType Edit',
            path: ':idTrainingType/edit',
            meta: { roles: ['ROLE_READ_TRAINING_TYPE', 'ROLE_WRITE_TRAINING_TYPE'] },
          },
        ],
      },
      {
        name: 'NotificationEmail List',
        path: 'notifications_email',
        alias: 'notificationEmail/list',
        component: () => import('@/views/dashboard/notificationEmail/List.vue'),
        meta: { roles: ['ROLE_READ_NOTIFICATION_EMAIL'] },
      },
      {
        name: 'NotificationEmail',
        path: 'notification_email',
        redirect: { name: 'NotificationEmail List' },
        component: () =>
          import('@/views/dashboard/notificationEmail/NotificationEmail.vue'),
        children: [
          {
            name: 'NotificationEmail Create',
            path: 'new',
            meta: { roles: ['ROLE_READ_NOTIFICATION_EMAIL', 'ROLE_WRITE_NOTIFICATION_EMAIL'] },
          },
          {
            name: 'NotificationEmail Edit',
            path: ':idNotificationEmail/edit',
            meta: { roles: ['ROLE_READ_NOTIFICATION_EMAIL', 'ROLE_WRITE_NOTIFICATION_EMAIL'] },
          },
        ],
      },
      {
        name: 'Configuration List',
        path: 'configurations',
        alias: 'configuration/list',
        component: () => import('@/views/dashboard/configuration/List.vue'),
        meta: { roles: ['ROLE_READ_CONFIGURATION'] },
      },
      {
        name: 'Configuration',
        path: 'configuration',
        redirect: { name: 'Configuration List' },
        component: () =>
          import('@/views/dashboard/configuration/Configuration.vue'),
        children: [
          {
            name: 'Configuration Edit',
            path: ':idConfiguration/edit',
            meta: { roles: ['ROLE_READ_CONFIGURATION', 'ROLE_WRITE_CONFIGURATION'] },
          },
        ],
      },
    ],
  },
  {
    path: '/forbidden',
    component: () => import('@/views/pages/Index.vue'),
    children: [
      {
        name: '403 Error',
        path: '',
        meta: { public: true },
        component: () => import('@/views/pages/Error403.vue'),
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/views/pages/Index.vue'),
    children: [
      {
        name: '404 Error',
        path: '',
        meta: { public: true },
        component: () => import('@/views/pages/Error404.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})
function authorized (to: Route) {
  if (to.meta?.public) {
    return true
  }

  if (to.name !== 'Login' && to.meta.roles) {
    if (!userConnectedHasRoles(to.meta.roles)) {
      return false
    }
  }

  return store.getters['user/isUser'] || store.getters['user/isTrainer']
}

interface UserWindow extends Window {
  user: User;
}

/**
 * Router each auth
 */
router.beforeEach(async (to, from, next) => {
  if (to.path === '/logout') {
    try {
      await axios.get('/logout')
      await store.dispatch('user/logout')
      return next({ name: 'Login' })
    } catch (err) {
      return next({ name: from.name ? from.name : 'Trainee List' })
    }
  }

  if (
    !store.state.user.userConnected &&
    to.name !== 'Login' &&
    to.meta?.public !== true
  ) {
    store.commit('user/setRouteAfterLogin', to)
    if (process.env.NODE_ENV === 'development') {
      try {
        const userResponse = await axios.get('/me')
        store.commit('user/setUserConnected', userResponse.data)
        return next()
      } catch (err) {
        return next({ name: 'Login' })
      }
    } else {
      const user:User = (window as unknown as UserWindow).user
      if (user) {
        store.commit('user/setUserConnected', user)
        return next()
      }
    }
    return next({ name: 'Login' })
  }

  if (!authorized(to) && to.name !== '403 Error') {
    return next({ name: '403 Error' })
  }

  return next()
})

export default router
