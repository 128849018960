<template>
  <v-dialog
    ref="dialog"
    v-model="modalPicker"
    :open-on-click="!readonly"
    :return-value.sync="valueFormatHours ? timeFormated : dateIso"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider
        :name="label"
        :rules="rules"
      >
        <v-text-field
          v-model="timeFormated"
          slot-scope="{ errors }"
          :error-messages="errors"
          outlined
          :label="label"
          :class="textFieldClass + !readonly ? 'ignore-readonly-style' : '' "
          append-icon="mdi-clock"
          readonly
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
        />
      </validation-provider>
    </template>
    <v-time-picker
      v-model="timeFormated"
      :allowed-minutes="allowedStep"
      format="24hr"
      :min="minComputed"
      :max="maxComputed"
      scrollable
      :disabled="readonly"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="onCancel"
      >
        {{ $t('btn.cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(valueFormatHours ? timeFormated : dateIso)"
      >
        {{ $t('btn.validate') }}
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'
  import { format, formatISO, parse, parseJSON } from 'date-fns'

  export default defineComponent({
    name: 'TimeField',

    props: {
      value: {
        type: String,
        default: null,
      },
      valueFormatHours: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: 'Heure',
      },
      textFieldClass: {
        type: String,
        default: '',
      },
      min: {
        type: String,
        default: '',
      },
      max: {
        type: String,
        default: '',
      },
      allowedMinutes: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      rules: {
        type: String,
        default: 'required',
      },
      readonly: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data () {
      return {
        modalPicker: false,
        previousValue: null as string|null,
      }
    },

    watch: {
      modalPicker () {
        if (this.modalPicker && this.value) {
          this.previousValue = this.value
        }
      },
    },

    computed: {
      minComputed ():string {
        const defaultMin = '06:00'
        switch (this.valueFormatHours) {
          case true:
            return this.min ? this.min : defaultMin
          case false:
            return this.min ? format(parseJSON(this.min), 'HH:mm' as string) : defaultMin
        }
      },
      maxComputed ():string {
        const defaultMax = '23:30'
        switch (this.valueFormatHours) {
          case true:
            return this.max ? this.max : defaultMax
          case false:
            return this.max ? format(parseJSON(this.max), 'HH:mm' as string) : defaultMax
        }
      },
      timeFormated: {
        get (): string | null {
          switch (this.valueFormatHours) {
            case true:
              return this.value ? format(new Date('1970-01-01 ' + this.value), 'HH:mm') : null
            case false:
              return this.value ? format(parseJSON(this.value), 'HH:mm') : null
          }
        },
        set (value: string) {
          let referenceDate = null
          switch (this.valueFormatHours) {
            case true:
              referenceDate = value ? format(new Date('1970-01-01 ' + value), 'HH:mm') : value
              this.$emit('input', referenceDate)
              break
            case false:
              referenceDate = !this.value ? new Date() : this.value
              this.$emit(
                'input',
                formatISO(parse(value, 'HH:mm', parseJSON(referenceDate))),
              )
              break
          }
        },
      },
      dateIso: {
        get (): string | null {
          return (!this.valueFormatHours && this.value)
            ? formatISO(parseJSON(this.value), { representation: 'date' })
            : null
        },
        set () {
          this.$emit('input', this.value)
        },
      },
    },
    methods: {
      allowedStep (m: number): boolean {
        return this.allowedMinutes ? true : m % 15 === 0
      },
      onCancel () {
        this.$emit('input', this.previousValue)
        this.modalPicker = false
      },
    },
  })
</script>
