<template>
  <v-menu
    :dark="$vuetify.theme.dark"
    bottom
    origin="center center"
    transition="scale-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        class="mx-4 filter-header"
        v-bind="attrs"
        small
        :color="value && value.length > 0 ? 'primary' : 'grey'"
        v-on="on"
      >
        mdi-filter
      </v-icon>
    </template>
    <v-card
      :dark="$vuetify.theme.dark"
      inline
      class="px-5 mb-0"
      outlined
    >
      <DateField
        v-model="filterValue"
        class="pa-4"
        :label="label"
        rules=""
        :min="min"
        :max="max"
        :range="range"
        :date-to-show="dateToShow"
        is-clearable
        @input="filter"
        @validate="$emit('validate', $event)"
      />
      <v-btn
        class="ml-2 mb-2"
        :dark="$vuetify.theme.dark"
        small
        text
        :disabled="!value || value.length === 0"
        color="primary"
        @click="clear()"
      >
        {{ $t('btn.clear') }}
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
  import { defineComponent } from '@vue/composition-api'
  import { formatISO9075, parseJSON } from 'date-fns'

  export default defineComponent({
    name: 'DateFieldFilter',
    components: {
      DateField: () => import('@/components/base/DateField.vue'),
    },
    data () {
      return {
        filterValue: null as string | Array<string> | null,
      }
    },
    props: {
      label: {
        type: String,
      },
      value: {
        type: [String, Array],
        default: null,
      },
      range: {
        type: Boolean,
        default: false,
      },
      min: {
        type: String,
        default: '',
      },
      max: {
        type: String,
        default: '',
      },
      dateToShow: {
        type: String,
        default: '',
      },
    },
    created () {
      this.filterValue = this.value as string | Array<string>
    },
    watch: {
      value (newValue: string | Array<string>) {
        this.filterValue = newValue
      },
    },
    methods: {
      filter () {
        if (!this.range) {
          if (this.filterValue === null || this.filterValue === '') {
            this.clear()
            return
          }
          this.$emit('day-with-no-hours', formatISO9075(parseJSON(this.filterValue as string)))
          return
        }

        if (this.filterValue === null || this.filterValue.length === 0) {
          this.clear()
        }
      },
      clear () {
        if (this.range) {
          this.filterValue = []
          this.$emit('clear')
          return
        }
        this.filterValue = ''
        this.$emit('clear')
        this.$emit('day-with-no-hours', '')
      },
    },
  })
</script>
