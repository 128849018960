/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from '@/plugins/axios'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import UserModule from '@/store/modules/user'
import store from '@/store'
import {
  IFiltersPlanning,
  IFiltersTeachingToolsToAssociate,
  IFiltersTrainingOfficerView,
  IFiltersVehiclesToAssociate
} from '@/api/interfaces/filters'

interface FiltersState {
  isSubmittingPost: boolean,
}

@Module({ name: 'filters', namespaced: true })
class FiltersModule extends VuexModule implements FiltersState {
  public isSubmittingPost = false

  @Mutation
  public setIsSubmittingPost (isSubmittingPost: boolean): void {
    this.isSubmittingPost = isSubmittingPost
  }

  @Action({ rawError: true })
  public async saveFiltersPlanning (filters: IFiltersPlanning): Promise<string | void> {
    if (this.isSubmittingPost) {
      // Case method save is call three times to Post, data is the same
      return
    }

    const userConnected = UserModule.state.userConnected
    if (userConnected.userApplication.filters === null) {
      try {
        this.context.commit('setIsSubmittingPost', true)
        const filtersResponse = await http.post('api/filters', {
          filtersPlanning: filters,
        })
        userConnected.userApplication.filters = filtersResponse.data
        store.commit('user/setUserConnected', userConnected)
      } finally {
        this.context.commit('setIsSubmittingPost', false)
      }
    } else {
      if (JSON.stringify(userConnected.userApplication.filters.filtersPlanning) !== JSON.stringify(filters)) {
        await http.put('api/filters/' + userConnected.userApplication.filters.id, {
          filtersPlanning: filters,
        })
        userConnected.userApplication.filters.filtersPlanning = filters
        store.commit('user/setUserConnected', userConnected)
      }
    }
    return 'done'
  }

  @Action({ rawError: true })
  public async saveFiltersTrainingOfficerView (filters: IFiltersTrainingOfficerView): Promise<void> {
    if (this.isSubmittingPost) {
      // Case method save is call multiple times to Post, data is the same
      return
    }

    const userConnected = UserModule.state.userConnected
    if (userConnected.userApplication.filters === null) {
      try {
        this.context.commit('setIsSubmittingPost', true)
        const filtersResponse = await http.post('api/filters', {
          filtersTrainingOfficerView: filters,
        })
        userConnected.userApplication.filters = filtersResponse.data
        store.commit('user/setUserConnected', userConnected)
      } finally {
        this.context.commit('setIsSubmittingPost', false)
      }
    } else {
      if (JSON.stringify(userConnected.userApplication.filters.filtersTrainingOfficerView) !== JSON.stringify(filters)) {
        await http.put('api/filters/' + userConnected.userApplication.filters.id, {
          filtersTrainingOfficerView: filters,
        })
        userConnected.userApplication.filters.filtersTrainingOfficerView = filters
        store.commit('user/setUserConnected', userConnected)
      }
    }
  }

  @Action({ rawError: true })
  public async saveFiltersVehiclesToAssociate (filters: IFiltersVehiclesToAssociate): Promise<void> {
    if (this.isSubmittingPost) {
      // Case method save is call multiple times to Post, data is the same
      return
    }

    const userConnected = UserModule.state.userConnected
    if (userConnected.userApplication.filters === null) {
      try {
        this.context.commit('setIsSubmittingPost', true)
        const filtersResponse = await http.post('api/filters', {
          filtersVehiclesToAssociate: filters,
        })
        userConnected.userApplication.filters = filtersResponse.data
        store.commit('user/setUserConnected', userConnected)
      } finally {
        this.context.commit('setIsSubmittingPost', false)
      }
    } else {
      if (JSON.stringify(userConnected.userApplication.filters.filtersVehiclesToAssociate) !== JSON.stringify(filters)) {
        await http.put('api/filters/' + userConnected.userApplication.filters.id, {
          filtersVehiclesToAssociate: filters,
        })
        userConnected.userApplication.filters.filtersVehiclesToAssociate = filters
        store.commit('user/setUserConnected', userConnected)
      }
    }
  }

  @Action({ rawError: true })
  public async saveFiltersTeachingToolsToAssociate (filters: IFiltersTeachingToolsToAssociate): Promise<void> {
    if (this.isSubmittingPost) {
      // Case method save is call multiple times to Post, data is the same
      return
    }

    const userConnected = UserModule.state.userConnected
    if (userConnected.userApplication.filters === null) {
      try {
        this.context.commit('setIsSubmittingPost', true)
        const filtersResponse = await http.post('api/filters', {
          filtersTeachingToolsToAssociate: filters,
        })
        userConnected.userApplication.filters = filtersResponse.data
        store.commit('user/setUserConnected', userConnected)
      } finally {
        this.context.commit('setIsSubmittingPost', false)
      }
    } else {
      if (JSON.stringify(userConnected.userApplication.filters.filtersTeachingToolsToAssociate) !== JSON.stringify(filters)) {
        await http.put('api/filters/' + userConnected.userApplication.filters.id, {
          filtersTeachingToolsToAssociate: filters,
        })
        userConnected.userApplication.filters.filtersTeachingToolsToAssociate = filters
        store.commit('user/setUserConnected', userConnected)
      }
    }
  }
}
export default FiltersModule
