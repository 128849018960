import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { Emargement } from '@/api/interfaces/emargement'
import { DayPeriodSignEmargement } from '@/enums/dayPeriodSignEmargement'
import { useFile } from '@/composables/file'

export interface EmargementState {
  list: Array<Emargement>
  totalItems: number
  loading: boolean
  signing: boolean
  loadingChangeEmail: boolean
  loadingSendAllEmails: boolean
  loadingUpdateEmargementNeedTraining: boolean
  loadingValidateUnvalidateEmargement: boolean
  loadingDeleteSignature: boolean
}

interface EmargementEdit {
  needTraining: {
    trainee: {
      firstName: string,
      lastName: string,
      email: string,
    }
  }
  emargementNeedTraining: {
    firstName: string,
    lastName: string,
    email: string,
    company: string,
  }
  booking: {
    firstName: string,
    lastName: string,
    email: string,
    company: string,
  }
}

@Module({ name: 'emargement', namespaced: true })
class EmargementModule extends VuexModule implements EmargementState {
  public list: Array<Emargement> = []
  public totalItems = 0
  public loading = false
  public signing = false
  public loadingChangeEmail = false
  public loadingSendAllEmails = false
  public loadingUpdateEmargementNeedTraining = false
  public loadingValidateUnvalidateEmargement = false
  public loadingDeleteSignature = false

  @Mutation
  public setList (list: Array<Emargement>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (totalItems: number): void {
    this.totalItems = totalItems
  }

  @Mutation
  public setLoading (loading: boolean): void {
    this.loading = loading
  }

  @Mutation
  public setSigning (signing: boolean): void {
    this.signing = signing
  }

  @Mutation
  public setLoadingChangeEmail (loadingChangeEmail: boolean): void {
    this.loadingChangeEmail = loadingChangeEmail
  }

  @Mutation
  public setLoadingSendAllEmails (loadingSendAllEmails: boolean): void {
    this.loadingSendAllEmails = loadingSendAllEmails
  }

  @Mutation
  public setLoadingUpdateEmargementNeedTraining (loadingUpdateEmargementNeedTraining: boolean): void {
    this.loadingUpdateEmargementNeedTraining = loadingUpdateEmargementNeedTraining
  }

  @Mutation
  public setLoadingValidateUnvalidateEmargement (loadingValidateUnvalidateEmargement: boolean): void {
    this.loadingValidateUnvalidateEmargement = loadingValidateUnvalidateEmargement
  }

  @Mutation
  public setLoadingDeleteSignature (loadingDeleteSignature: boolean): void {
    this.loadingDeleteSignature = loadingDeleteSignature
  }

  @Action({ rawError: true })
  public async loadEmargementTrainerAndNeedTrainings (
    params: {
      logisticSessionId: number;
      trainingSessionId: number;
    },
  ): Promise<Array<Emargement>> {
    this.context.commit('setList', [])
    this.context.commit('setLoading', true)
    const response = await http.get(`api/emargements/logistic_sessions/${params.logisticSessionId}/training_sessions/${params.trainingSessionId}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading', false)
    return response.data['hydra:member'] as Array<Emargement>
  }

  @Action({ rawError: true })
  public async loadEmargementNeedTrainings (uuidSession: string): Promise<Array<Emargement>> {
    this.context.commit('setList', [])
    this.context.commit('setLoading', true)
    const response = await http.get(`api/emargements/${uuidSession}/need_trainings`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('setLoading', false)
    return response.data['hydra:member'] as Array<Emargement>
  }

  @Action({ rawError: true })
  public async loadEmargement (uuid: string): Promise<Array<Emargement>> {
    this.context.commit('setList', [])
    this.context.commit('setLoading', true)
    const response = await http.get(`api/emargements/${uuid}`)
    this.context.commit('setList', [response.data])
    this.context.commit('setTotalItems', 1)
    this.context.commit('setLoading', false)
    return response.data['hydra:member'] as Array<Emargement>
  }

  @Action({ rawError: true })
  public async addEmargementNeedTraining (params: {
    uuidSession: string,
    body: {
      lastName: string,
      firstName: string,
      email: string,
      company: string,
    },
  }): Promise<Emargement> {
    try {
      this.context.commit('setLoadingUpdateEmargementNeedTraining', true)
      return await http.post(`api/emargements/${params.uuidSession}/need_trainings`, params.body)
    } finally {
      this.context.commit('setLoadingUpdateEmargementNeedTraining', false)
    }
  }

  @Action({ rawError: true })
  public async sign (
    params: {
      uuid: string;
      signature: File;
      dayPeriod: DayPeriodSignEmargement;
    },
  ): Promise<Emargement> {
    try {
      this.context.commit('setSigning', true)
      const signatureData = new FormData()
      signatureData.append('file', params.signature)
      return await http.post(
        `api/emargements/${params.uuid}/sign/${params.dayPeriod}`,
        signatureData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
    } finally {
      this.context.commit('setSigning', false)
    }
  }

  @Action({ rawError: true })
  public async editEmargement (
    params: {
      uuid: string;
      body: EmargementEdit;
    },
  ): Promise<Emargement> {
    try {
      this.context.commit('setLoadingUpdateEmargementNeedTraining', true)
      const response = await http.put(`api/emargements/${params.uuid}`, params.body)
      return response.data
    } finally {
      this.context.commit('setLoadingUpdateEmargementNeedTraining', false)
    }
  }

  @Action({ rawError: true })
  public async editAbsentNeedTraining (
    params: {
      uuid: string;
      isAbsent: boolean;
    },
  ): Promise<Emargement> {
    const response = await http.put(`api/emargements/${params.uuid}`, {
      isAbsent: params.isAbsent,
    })
    return response.data
  }

  @Action({ rawError: true })
  public async editEmailNeedTraining (
    params: {
      uuid: string;
      email: string;
    },
  ): Promise<Emargement> {
    try {
      this.context.commit('setLoadingChangeEmail', true)
      const response = await http.put(`api/emargements/${params.uuid}/confirm_email`, {
        emailNeedTraining: params.email,
      })
      return response.data
    } finally {
      this.context.commit('setLoadingChangeEmail', false)
    }
  }

  @Action({ rawError: true })
  public async sendEmail (uuid: string): Promise<Emargement> {
    return await http.post(`api/emargements/need_trainings/${uuid}/send_email`, {})
  }

  @Action({ rawError: true })
  public async sendAllEmails (uuidSession: string): Promise<Emargement> {
    try {
      this.context.commit('setLoadingSendAllEmails', true)
      return await http.post(`api/emargements/${uuidSession}/send_emails`, {})
    } finally {
      this.context.commit('setLoadingSendAllEmails', false)
    }
  }

  @Action({ rawError: true })
  public async loadSignature (params :{
    uuid: string, period: string
  }): Promise<{
    uuid: string,
    period: string,
    signature: string
  }> {
    const response = await http.get(
      `api/emargements/${params.uuid}/sign/${params.period}`,
      {
        responseType: 'arraybuffer',
      },
    )
    return {
      uuid: params.uuid,
      period: params.period,
      signature: useFile().convertBinaryToBlob(response.data, 'image/svg+xml'),
    }
  }

  @Action({ rawError: true })
  public async validateEmargement (uuidSession: string): Promise<void> {
    try {
      this.context.commit('setLoadingValidateUnvalidateEmargement', true)
      await http.post(`api/emargements/${uuidSession}/validate`, {})
    } finally {
      this.context.commit('setLoadingValidateUnvalidateEmargement', false)
    }
  }

  @Action({ rawError: true })
  public async unvalidateEmargement (uuidSession: string): Promise<void> {
    try {
      this.context.commit('setLoadingValidateUnvalidateEmargement', true)
      await http.post(`api/emargements/${uuidSession}/unvalidate`, {})
    } finally {
      this.context.commit('setLoadingValidateUnvalidateEmargement', false)
    }
  }

  @Action({ rawError: true })
  public async deleteSignature (
    params: {
      uuid: string,
      dayPeriod: DayPeriodSignEmargement,
    }
  ): Promise<void> {
    try {
      this.context.commit('setLoadingDeleteSignature', true)
      await http.put(`api/emargements/${params.uuid}/delete/${params.dayPeriod}`, {})
    } finally {
      this.context.commit('setLoadingDeleteSignature', false)
    }
  }
}

export default EmargementModule
